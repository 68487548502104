<template>
  <Section class="entry" wide-mode no-space :class="{ 'has-cover': showCoverOnMobile }">
    <article-breacrumb v-if="breadcrumb.length" :breadcrumb="breadcrumb" />
    <div class="main">
      <div class="content-wrapper">
        <h1 class="title">{{ title }}</h1>
        <cms-components-rich-text
          class="description"
          :text="description"
          :class="{ 'last-item': !updateDate && tags.length === 0 }"
        />
        <article-tags v-if="tags.length" :tags="tags" />
        <div v-if="!!updateDate" class="update-date">Mise à jour le {{ updateDateFormated }}</div>
      </div>
      <div class="cover-container">
        <div>
          <div class="cover" :class="{ 'hide-on-mobile': !showCoverOnMobile }">
            <cms-components-shared-image
              fit-cover
              :image="cover"
              size="cover"
              no-lazy
              :responsive="[
                { width: '420px', size: 'small' },
                { width: '*', size: 'cover' },
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </Section>
</template>

<script>
import { dateFormat } from '@hz/ui-kit'

export default {
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    cover: {
      type: Object,
      required: true,
    },
    showCoverOnMobile: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    updateDate: {
      type: Date,
      default: undefined,
    },
  },
  computed: {
    updateDateFormated() {
      return dateFormat(this.updateDate, 'dd mmmm yyyy')
    },
  },
}
</script>

<style lang="less" scoped>
.entry {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 32px;
  background: linear-gradient(58.89deg, #7442ec 28.07%, #b469f7 137.49%);

  color: var(--ds-color-white-100);

  &.has-cover {
    margin-bottom: 92px;

    .container {
      width: 528px;
      right: -186px;
      bottom: -80px;
    }
  }
}

:deep(.section-container) {
  position: relative;
}

:deep(.hz-breadcrumb) {
  margin-bottom: 24px;
}

.title {
  font-weight: var(--ds-weight-semi-bold);
  font-size: 24px;
  margin: 0 0 12px 0;
}

.description:not(.last-item) {
  margin-bottom: 24px;
}

:deep(.hz-tags) {
  padding-bottom: 12px;
}

.update-date {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 32px;
  color: #c0b0fa;
}

.cover-container {
  display: flex;
  align-items: center;
}

.cover {
  display: flex;
  border-radius: 10px;
  padding: 6px 8px;
  margin-bottom: -92px;
  min-width: 326px;

  background: var(--ds-color-white-100);
  overflow: hidden;

  filter: drop-shadow(0px 15px 8px rgba(0, 0, 0, 0.15));

  :deep(img) {
    max-width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}

@media @bp-desktop {
  :deep(.section-container) {
    display: flex;
    flex-direction: column;
  }

  .main {
    display: flex;
  }

  .content-wrapper {
    flex: 0 0 60%;
    padding-right: 48px;
  }

  .title {
    font-size: 40px;
  }

  .cover {
    max-width: 520px;
    padding: 8px 8px;
    margin-bottom: 0;
  }

  .update-date {
    margin-bottom: 0;
  }
}
</style>
