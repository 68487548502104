<template>
  <div class="hz-breadcrumb">
    <template v-for="part in breadcrumb">
      <NuxtLink class="link" :to="part.to">
        {{ part.label }}
      </NuxtLink>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="less" scoped>
.hz-breadcrumb {
  .link {
    color: var(--ds-color-primary-50)!important;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;

    &:last-child {
      color: var(--ds-color-white-100)!important;
      font-weight: 500;
    }

    &:not(:last-child):after {
      content: '/';
      margin-right: 4px;
      margin-left: 4px;
      color: var(--ds-color-primary-50);
      font-weight: var(--ds-weight-semi-bold);
      font-size: 14px;
    }
  }

  @media @bp-desktop {
    .link {
      font-weight: 300;
      font-size: 18px;
    }
  }
}
</style>
