<template>
  <div class="hz-tags">
    <uikit-tag-list
      :tags="capitalizedTags"
      class="tag"
    />
  </div>
</template>

<script setup>
import { UikitTagList } from '@hz/ui-kit'
const props = defineProps({
  tags: {
    type: Array,
    default: () => [],
  },
})

const capitalizedTags = computed(() => {
  return props.tags.map((string, index) => ({
    label: string.charAt(0).toUpperCase() + string.slice(1),
    type: 'default',
    value: index,
  }))
})
</script>

<style lang="less" scoped>
.hz-tags {
  .tag {
    height: auto;
    margin-top: 12px;
    margin-right: 8px;
  }
}
</style>
